import { Link } from 'gatsby'

import colors from '../../styles/colors'
import { device } from '../../styles/media'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

export const MainShape = styled(Grid)`
  background-image: linear-gradient(${colors.primaryLight}, ${colors.primaryDark});
  color: white;
  height: 400px;
  margin: 0;
  width: 100vw;
`

export const StyledContainer = styled(Container)`
  font-size: 2rem;
  padding-top: 200px;
  text-align: center;
`

export const StyledH2 = styled.h2`
  font-weight: 600;
  margin-top: 0;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`
export const StyledP = styled.p`
  @media ${device.tablet} {
    margin-top: 0;
  }
`
export const StyledSpan = styled.span`
  color: ${colors.textLight};
  font-size: 1rem;
`

export const StyledLink = styled(Link)`
  color: white;
  font-size: 1rem;
  text-decoration: none;
  transition: 0.25s;

  :hover {
    color: ${colors.secondary};
  }
`

export const BlogContainer = styled(Grid)`
  margin: 100px auto;
  padding: 25px;
  width: 700px;
`

export const BlogItem = styled(Grid)``

export const BlogLink = styled(Link)`
  color: ${colors.primaryDark};
  display: block;
  font-size: 2rem;
  font-weight: 400;
  text-decoration: none;

  :hover {
    color: ${colors.textDark};
  }
`

export const BlogPreview = styled.p`
  color: ${colors.textMedium};
  font-size: 1.25rem;
`

export const BlogPreviewLink = styled(Link)`
  color: ${colors.primaryLight};
  cursor: pointer;
  display: block;
  font-size: 1.25rem;
  margin-top: 20px;
  text-decoration: none;

  :hover {
    color: ${colors.textDark};
  }
`
