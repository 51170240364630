import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

import Main from '../../components/Blog/Main'

const BlogPage = () => (
  <Layout>
    <SEO title="Blog" />

    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Learn how we can transform your expectations of custom software." />
      <title>Blog | LaMar Software</title>
      <link rel="canonical" href="https://lamarsoftware.io/blog" />
    </Helmet>

    <Main />
  </Layout>
)

export default BlogPage
